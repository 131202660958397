import { css } from '@emotion/react';

export const containerCx = css`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  user-select: none;
  pointer-events: none;
`;
