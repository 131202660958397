import type { VFC } from 'react';
import Image from 'next/image';
import Typography from '@mui/material/Typography';

import { containerCx } from './styles';

/**
 * @function FullScreen Presentational component
 */
const FullScreen: VFC<unknown> = () => {
  return (
    <div css={containerCx}>
      <Image src="/logo/128.png" alt="BestHome" width={68} height={68} unoptimized />
      <Typography align="center" variant="h3" component="h1" marginTop={1}>
        besthome
      </Typography>
    </div>
  );
};

export default FullScreen;
