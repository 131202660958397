import dynamic from 'next/dynamic';

import { useMobile } from '@/context/responsive';

import FullScreen from '@/components/Placeholder/FullScreen';
import { NextModules } from '@/model/types';

const Desktop = dynamic(() => import(/* webpackChunkName: "desktop-home" */ './Desktop'), {
  ssr: true,
  loading: () => <FullScreen />,
});

const Mobile = dynamic(() => import(/* webpackChunkName: "mobile-home" */ './Mobile'), {
  ssr: true,
  loading: () => <FullScreen />,
});

/**
 * @function DeviceLayout
 */
const DeviceLayout: NextModules = props => {
  const { children, ...rest } = props;
  const isMobile = useMobile();

  if (isMobile) {
    return <Mobile {...rest}>{children}</Mobile>;
  }
  return <Desktop {...rest}>{children}</Desktop>;
};

export default DeviceLayout;
