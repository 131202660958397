import type { VFC } from 'react';
import dynamic from 'next/dynamic';

import { useMobile } from '@/context/responsive';

import FullScreen from '@/components/Placeholder/FullScreen';

import type { DeviceLayoutProps } from './types';

const Desktop = dynamic<DeviceLayoutProps>(() => import(/* webpackChunkName: "desktop-layout" */ './Desktop'), {
  ssr: true,
  loading: () => <FullScreen />,
});

const Mobile = dynamic<DeviceLayoutProps>(() => import(/* webpackChunkName: "mobile-layout" */ './Mobile'), {
  ssr: true,
  loading: () => <FullScreen />,
});

/**
 * @function DeviceLayout
 */
const DeviceLayout: VFC<DeviceLayoutProps> = props => {
  const { children, ...rest } = props;
  const isMobile = useMobile();

  if (isMobile) {
    return <Mobile {...rest}>{children}</Mobile>;
  }
  return <Desktop {...rest}>{children}</Desktop>;
};

export default DeviceLayout;
