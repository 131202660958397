import dynamic from 'next/dynamic';

import FullScreen from '@/components/Placeholder/FullScreen';
import { NextModules } from '@/model/types';

const Page = dynamic(() => import(/* webpackChunkName: "mobile-home" */ './HomeAgent'), {
  ssr: true,
  loading: () => <FullScreen />,
});

/**
 * @function DeviceLayout
 */
const DeviceLayout: NextModules = props => {
  const { children, ...rest } = props;

  return <Page {...rest}>{children}</Page>;
};

export default DeviceLayout;
