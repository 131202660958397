import type { GetServerSideProps } from 'next';
import { QueryClient, dehydrate } from 'react-query';
import sanitize from '@/utils/json/sanitize';
import { prefetchIsAuthenticate } from '@/repository/is-authentication/server';

import type { NextPageWithLayout } from '@/model/types';

import DeviceLayout from '@/layouts/DeviceLayout';
import Module from '@/routes/Home';
import ModuleAgent from '@/routes/Agent/Home';
import { useAuth } from '@/context/authentication';

/**
 * @function Page
 * @param props
 */
const Page: NextPageWithLayout = props => {
  const { isAgent } = useAuth();
  return isAgent ? <ModuleAgent {...props} /> : <Module {...props} />;
};

/**
 * @function getServerSideProps
 * @param context
 */
export const getServerSideProps: GetServerSideProps = async context => {
  const client = new QueryClient();

  await prefetchIsAuthenticate(client, context);

  const dehydratedState = dehydrate(client);
  return {
    props: {
      dehydratedState: sanitize(dehydratedState),
    },
  };
};

Page.getLayout = page => {
  return (
    <DeviceLayout withDesktopTopNavigation withDesktopTopNavigationTranslucentBehaviour withBottomNavigation>
      {page}
    </DeviceLayout>
  );
};

export default Page;
